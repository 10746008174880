<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    show-expand
    item-key="id"
    sort-by="emited"
    sort-desc
    class="elevation-1"
    @current-items="currentItems"
    :loading="loading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>LOU List</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          v-if="search_tool"
          v-model="search"
          ref="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>

        <!-- BOM Filtros -->
        <v-dialog v-if="search_tool" v-model="dialogFilters" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-4"
              color="blue-grey"
              fab
              small
              dark
              v-on="on"
              @click="dialogFilters = true"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Advanced Search</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-menu
                      ref="rangeCalendar"
                      v-model="rangeCalendar"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-x
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          label="Dates"
                          hint="Enter a date range range. Separate with '~'"
                          persistent-hint
                          prepend-icon="event"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="rangeDates" no-title range>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="rangeDates = []">Clear</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rangeCalendar.save(rangeDates);"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-text-field
                    v-model="filterWorkOrder"
                      label="Work Order"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-autocomplete
                      :items="[
                        'All',
                        'Yes',
                        'No'
                      ]"
                      label="Cancelled"
                      v-model="filterCancelled"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="clearFilterItems()">Clear Filters</v-btn>
              <v-btn color="blue darken-1" text @click="dialogFilters = false;">Close</v-btn>
              <v-btn color="blue darken-1" text @click="filterItems()">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- EOM Filtros -->

        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="750px">
          <template v-slot:activator="{ on }">
            <v-btn 
              color="primary" 
              dark class="mb-2" 
              v-on="on" 
              @click="formNewItem()"
            >New LOU</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row v-if="formNewtItem" class="form-first-field">
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      prepend-icon="folder"
                      placeholder="Select Work Order"
                      :items="workOrdersList"
                      item-text="id"
                      item-value="id"
                      label="Work Order"
                      v-model="current_wo_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-switch false-value="0" true-value="1" v-model="editedItem.replaced" color="info" hide-details>
                      <template v-slot:label>
                        LOU Replaced:
                        <v-chip
                          color="green"
                          dark
                          v-if="editedItem.replaced == 1"
                          class="ml-2"
                        >replaced</v-chip>
                        <v-chip color="red" dark v-else class="ml-2">pending</v-chip>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.beneficiary" label="Beneficiary"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.amount" type="number" prefix="$" label="Amount"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="popDatepick1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      tabindex=-1
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.emited"
                          label="Emitted"
                          prepend-icon="event"
                          :placeholder="today"
                          readonly
                          v-on="on"
                          tabindex=-1
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.emited" @input="popDatepick1 = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="popDatepick2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.cancelled"
                          label="Cancelled"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.cancelled"
                        @input="popDatepick2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea v-model="editedItem.comments" label="Comments"></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save(items)">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog2" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle2 }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-switch false-value="0" true-value="1" v-model="editedItem.replaced" color="info" hide-details>
                      <template v-slot:label>
                        LOU Replaced:
                        <v-chip
                          color="green"
                          dark
                          v-if="editedItem.replaced ==  1"
                          class="ml-2"
                        >replaced</v-chip>
                        <v-chip color="red" dark v-else class="ml-2">pending</v-chip>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea v-model="editedItem.comments" label="comments"></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save(items)">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.replaced="{ item }">
      <v-chip color="green" dark v-if="item.replaced == 1">replaced</v-chip>
      <v-chip color="red" dark v-else>pending</v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="replaceItem(item, 'dialog2')"
        v-b-tooltip.hover
        title="Replace"
      >mdi-file-replace</v-icon>
      <v-icon
        small
        class="mr-2"
        @click="editItem(item, 'dialog')"
        v-b-tooltip.hover
        title="Edit"
      >mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item, items)" v-b-tooltip.hover title="Delete">mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="reset">Reset</v-btn>
    </template>
    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length + 1">
        <div class="row justify-center">
          <div class="col-md-11">
            <div class="row mt-3">
              <div class="col-5">
                <v-container fluid>
                  <h5>Comments:</h5>
                  <p>
                    {{ item.comments }}
                  </p>
                </v-container>
              </div>
            </div>
          </div>
        </div>
      </td>
    </template>
    <template v-slot:item.amount="{ item }">
      {{
      formatAmount(item.amount)
      }}
    </template>
    <template slot="footer">
      <table>
        <tr class="border border-right-0 border-left-0 border-bottom-0 text-center">
          <td colspan>
            <v-chip color="lighten-1 mr-4">
              Items: &nbsp;
              <strong class="ml-2">{{ itemsFiltered }}</strong>
            </v-chip>
            <v-chip color="lighten-1">
              Amount: &nbsp;
              <strong class="ml-2">{{ totalAmount }}</strong>
            </v-chip>
          </td>
        </tr>
      </table>
    </template>
  </v-data-table>
</template>

<script>
import genericService from "./../../../services/generic.service";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    uri: "lou",
    workOrdersList: [],
    singleExpand: false,
    expanded: [],
    formNewtItem: true,
    dialog: false,
    dialog2: false,
    search: "",
    radios: "",
    editedIndex: -1,
    totalAmount: 0,
    popDatepick1: false,
    popDatepick2: false,
    editedItem: {
      id: 0,
      work_order: "",
      replaced: 0,
      beneficiary: "",
      amount: null,
      emited: "",
      cancelled: null,
      comments: ""
    },
    defaultItem: {
      id: 0,
      work_order: "",
      replaced: 0,
      beneficiary: "",
      amount: null,
      emited: "",
      cancelled: null,
      comments: ""
    },
    switch1: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "Work Order", value: 0 },
      { text: "Beneficiary", value: 2 },
      { text: "Amount", value: 3 },
      { text: "Comments", value: 6 }
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0,
    filterCancelled:"",
    filterWorkOrder:"",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New LOU" : "Edit LOU";
    },
    formTitle2() {
      return this.editedIndex === -1 ? "Replace LOU" : "Replace LOU";
    },
    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    },
    ...mapGetters(["currentUser"]),
  },

  props: [
      "headers", 
      "items", 
      "search_tool",
      "current_wo_id"
  ],

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    }
  },

  methods: {
    editItem(item, dialog) {
      /* Listado de Dialogs */
      if (dialog == "dialog") {
        this.dialog = true;
        this.formNewtItem = false;
      }
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    replaceItem(item, dialog) {
      /* Listado de Dialogs */
      if (dialog == "dialog2") {
        this.dialog2 = true;
      }
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item, list) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        const index = list.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          Swal.fire({
            title: 'Deleted!',
            text: "Your record has been deleted.",
            icon: 'success'
          })
        }
      })
    },

    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      this.dialog2 = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save(items) {
      var _self = this;
      _self.editedItem.work_order = _self.current_wo_id;
      
      if(_self.editedItem.work_order == "" || 
      _self.editedItem.work_order == undefined || 
      _self.editedItem.work_order == null || 
        _self.editedItem.beneficiary == "" || 
        _self.editedItem.amount == "" || 
        _self.editedItem.emited == "") {
        Swal.fire({
            title: "",
            text: "Fields Work Order, Beneficiary, Amount and Emitted are mandatories.",
            icon: "warning",
            confirmButtonClass: "btn btn-secondary"
          });
          return;
      }


      this.editedItem.amount = this.toNumber(this.editedItem.amount);

      if (this.editedIndex > -1) {
        Object.assign(items[this.editedIndex], this.editedItem);
        genericService.updateRecord("/"+this.uri+"/" + this.editedItem.id, this.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      } else {
        this.editedItem.work_order = this.current_wo_id;
        items.push(this.editedItem);

        genericService.createRecord("/"+this.uri, this.editedItem, function(rta){
          if(rta != undefined) {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
            _self.editedItem.id = rta.id;
          }
          
        });
      }
      this.close();
    },

    reset() {
      this.$emit("initialize");
    },

    toNumber(nr) {
      nr = parseFloat(nr);
      return nr;
    },

    formatCurrencyAmount(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      });

      return formatter.format(amount);
    },

    formatAmount(nr) {
      if((nr == undefined) || (nr == null) || (isNaN(nr))) {
        return 0;
      }
      return nr;
    },

    /* Filtros & Loading */
    currentItems(items) {
      this.loading = true;
      setTimeout(() => {
        this.itemsFiltered = items.length;

        var sum = items.reduce((a, item) => a + this.formatAmount(parseFloat(item.amount)), 0);

        this.totalAmount = this.formatCurrencyAmount(sum);

        this.loading = false;
      }, 300);
    },
    
    /* BOM - Filtros */
    clearFilterItems() {
      var _self = this;
      _self.rangeDates = [];
      _self.dateRangeText = "";
      _self.filterWorkOrder = "";
      _self.filterCancelled = "";

      genericService.getRecordListWithLimit('/'+this.uri, 10, "emited", _self.currentUser.role,_self.currentUser.id,function(records){
         _self.items = records;
         _self.dialogFilters = false;
      });
    },

    filterItems() {
      var _self = this;
      var params = {
        "module": _self.uri,
        "emitted": _self.rangeDates,
        "wo": _self.filterWorkOrder,
        "cancelled": _self.filterCancelled,
      }

      genericService.getRecordListWithParams(params, _self.currentUser.role, _self.currentUser.id, function(records){
         _self.items = records;
         _self.dialogFilters = false;
      });
    },

    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },

    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },
    /* EOM - Filtros */
    
    formNewItem(){
      this.formNewtItem = true;
      setTimeout(() => {
        this.focusField(this.$refs.formFirstField);
      }, 200)
    }
  },
  mounted() {
    if(this.$route.params.id == "new"){
      setTimeout(() => {
        this.dialog = true;
        this.formNewItem();
      }, 700);
    }else{
      if(this.search_tool == true){
        this.focusField(this.$refs.search);
      }
    }

    var _self = this;

    genericService.getRecordList('/workorders/', function(records){
        _self.workOrdersList = records.reverse();
    });
  }
};
</script>
