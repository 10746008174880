<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <LouList
            :headers="headersLou"
            :items="itemsLou"
            @initialize="initialize"
            :search_tool="true"
          ></LouList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import LouList from "@/views/pages/lou/LouList.vue";
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  components: {
    LouList,
  },

  data: () => ({
    uri: "lou",
    headersLou: [
      { text: "Work Order", value: "work_order" },
      { text: "Replaced", value: "replaced", sortable: true },
      { text: "Beneficiary", value: "beneficiary" },
      { text: "Amount", value: "amount" },
      { text: "Emitted", value: "emited" },
      { text: "Cancelled", value: "cancelled" },
      { text: "Actions", value: "actions" },
    ],
    itemsLou: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      genericService.getRecordListWithLimit(
        "/" + this.uri,
        100,
        "emited",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsLou = records;
        }
      );
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Letters of Undertaking" }]);
  },

  computed: {
      ...mapGetters(["currentUser"]),
  },
};
</script>
